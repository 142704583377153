import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigProfileModel } from 'src/app/common/core/api-interfaces/config-profile';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigProfileService {

  constructor(private http: HttpClient) { }

  // Get profiles by entity ----------------------------------
  getConfigProfileList(body: any) {
    const url = environment.server_url + "/tc/v1/config/list-profiles-by-entity";
    return this.http.post<ConfigProfileModel[]>(url, body);
  }

  // Get profiles ----------------------------------
  getProfileList(body: any) {
    const url = environment.server_url + "/tc/v1/config/list-profiles";
    return this.http.post<ConfigProfileModel[]>(url, body);
  }

  // modify profile
  modifyProfile(body: any) {
    const url = environment.server_url + "/tc/v1/config/modify-profile";
    return this.http.post<ConfigProfileModel>(url, body);
  }
}
